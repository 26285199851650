<template>
    <div class="mall-detail">
        <van-swipe @change="onChange">
            <van-swipe-item v-for="(item, i) in picList" :key="i">
                <van-image class="inv-image" fit="cover" :src="item.url"/>
            </van-swipe-item>
        </van-swipe>
        <div class="inv">
            <div class="price">
                ￥{{information.price}}
            </div>
            <div class="name">
                {{information.invName}}
            </div>
        </div>
        <div class="desc" v-html="information.detailsContent"></div>

        <van-popup class="my-popup" v-model="showGoods">
            <div class="goods">
                <div class="goods-info">
                    <div class="goods-info__img">
                        <!-- <van-image class="my-image" fit="fill" :src="imgUrl1" alt=""/> -->
                        <van-image class="my-image" fit="cover" :src="getImg(information.picUrl)" alt="">
                            <template v-slot:error><van-image class="my-image" fit="fill" :src="imgUrl1" alt=""/></template>
                        </van-image>
                        <!-- <i class="iconfont icon-xiazai"></i> -->
                    </div>
                    <div class="goods-info__desc">
                        <div class="money">
                            {{information.price}} 元
                        </div>
                        <div class="invName">
                            <div>{{information.invName}}</div>
                            <!-- <div>库存{{quantity}}{{goods.invUnit}}</div> -->
                        </div>
                    </div>
                </div>
                <div class="total">
                    <div class="total-money">小计: ￥{{subtotal}}</div>
                    <van-stepper v-model="num" step="1" min="1"  @change="changeNum"/>
                </div>

                <div class="integral">
                    <div class="int"><span>余额</span><span class="color">({{balanceTotal}})</span></div>
                    <div class="int"><span>使用余额</span><input class="input" type="number" ref="inputs" v-model="balance" @input="onChageInputBa"/></div>
                    <van-checkbox v-model="checkedBalance" @change="onChageInputBa"></van-checkbox>
                </div>

                <van-cell-group v-if="information.logisticsFlag === 1">
                    <van-field class="my-filed" v-model="mobile" required label="手机号" placeholder="请输入手机号" />
                    <van-field class="my-filed" v-model="address" required label="收货地址" placeholder="请输入收货地址" />
                </van-cell-group>
                
                <div class="btn">
                    <div class="car" @click="addCar">立即支付￥{{realSubTotal}}</div>
                    <div class="close" @click="showGoods = false">关闭</div>
                </div>
            </div>
        </van-popup>

        <!-- 结算 -->
        <van-submit-bar :disabled="$help.storeTemp.get('personType') !== 0" class="my-submit-bar" :price="realSubTotal*100" button-text="提交订单" @submit="onSubmitOrder" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            picList: [],
            information: {},
            current: 0,
            num: 1,
            goods: {
                price: 0,
                name: '',
                invUnit: '',
                title: '',
                way: ''
            },
            imgUrl1: require('@/assets/img/horse1.jpg'),
            //金额换算
            conversionBa: 1,
            balanceTotal: 0,
            balance: 0,
            checkedBalance: false,
            showGoods: false,
            mobile: '',
            address: ''
        }
    },
    computed: {
        subtotal() {
            let total = 0;
            total = (this.information.price-0) * this.num
            return total.toFixed(2)
        },
        realSubTotal(){
            if(this.checkedBalance){
                return (this.subtotal - this.balance*this.conversionBa).toFixed(2)
            }else{
                return this.subtotal
            }
        }
    },
    methods:{
        getInfo() {
            this.$help.loading()
            this.$api.post('common/base/inv/inv-show', this.$route.query).then(res=>{
                let picList = []
                if(res.picUrl){
                    picList = res.picUrl.split(','); 
                    picList.forEach(ele=>{
                        this.picList.push({url: this.getImg(ele), isImage: true })
                    })
                }
                this.information = res
            })
            .finally(this.$help.loaded)
        },
        onChange(index) {
            this.current = index;
        },
        changeNum() {
            let balance = this.subtotal*this.conversionBa
            if( balance >= this.balanceTotal){
                this.balance = this.balanceTotal
            }else{
                this.balance = balance
            }
        },
        getMemberUser() { 
            this.$store.dispatch('getMemberUser').then(() => {
                this.balanceTotal = this.$store.state.memberUser.amount
                this.address = this.$store.state.memberUser.receivingAddress
                this.mobile = this.$store.state.memberUser.mobile
            })
        },
        onChageInputBa() {
            if(this.balanceTotal > this.subtotal*this.conversionBa){
                //够
                if((this.$refs.inputs.value-0) >= this.subtotal*this.conversionBa){
                    this.$refs.inputs.value = this.subtotal*this.conversionBa
                    this.balance = this.subtotal*this.conversionBa
                }else{
                    this.balance = this.$refs.inputs.value 
                }
            }else{
                //不够
                if((this.$refs.inputs.value-0) >= this.balanceTotal){
                    this.$refs.inputs.value = this.balanceTotal
                    this.balance = this.balanceTotal
                }else{
                    this.balance = this.$refs.inputs.value 
                }
            }
        },
        onSubmitOrder() {
            this.showGoods = true
        },
        addCar() {
            let form = {}
            if(this.checkedBalance){
                this.$set(form, 'balanceAmount', this.balance)
            }
            console.log(this.information.logisticsFlag === 1 && this.address === '')
            if(this.information.logisticsFlag === 1 && this.mobile === '') {
                this.$help.error('请填写手机号')
                return
            }else if(this.information.logisticsFlag === 1){
                this.$set(form, 'phone', this.mobile)
            }
            if(this.information.logisticsFlag === 1 && this.address === '') {
                this.$help.error('请填写收货地址')
                return
            }else if(this.information.logisticsFlag === 1){
                this.$set(form, 'receivingAddress', this.address)
            } 
            this.$set(form, 'invId', this.information.id)
            this.$set(form, 'quantity', this.num)
            this.$set(form, 'payAmount', (this.realSubTotal-0))
            // console.log(form)
            this.$api.post('member/bill/shoppingOrder/shoppingOrder-save', form).then(res=>{
                //console.log(res)
                let url = window.encodeURIComponent(`${window.location.origin}/#/center/pay?orderId=${res.id}&orderType=${res.orderPayInfoVO.orderType}`)
                window.location.href = `${res.orderPayInfoVO.jumpGetOpenIdUrl}?returnUrl=${url}`
            })
        }

    },
    mounted() {
        this.getInfo()
        if(this.$help.storeTemp.get('personType') === 0) {
            //会员
            this.getMemberUser()
        }
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .mall-detail{
        .van-swipe{
            .inv-image{
                width: 100%;
            }
            .custom-indicator {
                position: absolute;
                right: 5px;
                bottom: 5px;
                padding: 2px 5px;
                font-size: 12px;
                background: rgba(0, 0, 0, 0.1);
            }
        }
        .inv{
            display: flex;
            padding: 10px 20px;
            align-items: center;
            background-color: $boxShadow;
            .price{
                background-color: $main;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                padding: 10px;
                border-radius: 9px;
            }
            .name{
                margin-left: 20px;
            }
        }
        .desc{
            padding-bottom: 50px;
        }
        .my-popup{
            border-radius: 19px;
            .goods {
                min-height: 65vh;
                width: 280px;
                border-radius: 19px;
                padding: 20px;
                &-info{
                    &__img{
                        border-radius: 19px;
                        .my-image{
                            height: 200px;
                            width: 100%;
                        }
                        img{
                            border-radius: 19px;
                        }
                    }
                    &__desc{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 5px 10px;
                        .money{
                            min-width: 80px;
                            padding: 10px;
                            border-radius: 9px;
                            background-color: $main;
                            color: #fff;
                            font-weight: bold;
                            text-align: center;
                        }
                        .invName{
                            margin-left: 20px;
                            width: 180px;
                            font-size: 12px;
                             display:-webkit-box;/**对象作为伸缩盒子模型展示**/
                            -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
                            -webkit-line-clamp:2;/**显示的行数**/
                            overflow:hidden;/**隐藏超出的内容**/
                        }
                    }
                }
                .total{
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &-money{
                        color: $red;
                        font-size: 14px;
                    }
                }
                .van-stepper{
                    
                    display: flex;
                    justify-content: flex-end
                }
                .integral{
                    padding: 10px 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top: 1px solid #ededed;
                    border-bottom: 1px solid #ededed;
                    .int{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .color{
                            color: $pink;
                        }

                    }
                    .input{
                        border: 0;
                        color: $pink;
                        outline: none;
                        border-bottom: 1px solid $pink;
                        width: 80px;
                        text-align: center;
                    }
                    .van-checkbox__icon--checked .van-icon{
                        background-color: $main;
                        border-color: $main;
                    }
                    // span{
                    //     color: $pink;
                    // }
                }
            }
            .btn{
                margin-top: 10px;
                .car{
                    background-color: $yellow;
                    border-radius: 9px;
                    text-align: center;
                    padding: 10px 0;
                    font-size: 16px;
                    //color: #fff;
                    //font-weight: bold;
                }
                .close{
                    text-align: center;
                    padding: 10px 0;
                }
            }
            
        }
        .my-submit-bar{
            .van-button{
                background: $main;
                border: 0;
            }
            .van-submit-bar__text{
                text-align: unset;
            }
        }
    }
</style>